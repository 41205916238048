<template>
    <div>
        <v-btn-toggle
                class="ml-3"
                color="primary"
                dense
                multiple
        >
            <v-tooltip bottom v-if="hasAddFunction">
                <template v-slot:activator="{ on }">
                    <v-btn @click="addFunction()">
                        <v-icon small dark v-on="on">ti ti-plus</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('message.new') }}</span>
            </v-tooltip>
            <v-menu offset-y>
                <template v-slot:activator="{ on: menu }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                                    v-on="{...tooltip, ...menu}">
                                <v-icon small dark v-on>ti ti-export</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.export') }}</span>
                    </v-tooltip>
                </template>
                <v-list>
                    <v-list-item @click="exportTable('pdf')">
                        <v-list-item-title>{{ $t('message.exportAsPdf') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="exportTable('excel')">
                        <v-list-item-title>{{ $t('message.exportAsExcel') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-btn-toggle>
    </div>
</template>

<script>
    import jsPDF from 'jspdf'
    import html2canvas from 'html2canvas'
    import XLSX from 'xlsx'
    import {delete_cols} from 'Helpers/excel'
    import {api} from "Api";
    import {mapFields} from "vuex-map-fields";

    export default {
        name: "ExportTableJsonWithAdd",
        props: ['exportName','exportData','exportFields','exportConditions','exportSource','hasAddFunction'],
        computed: {
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            })
        },
        methods: {
            addFunction(){
                this.$emit('add-action')
            },
            async exportTable(reportType) {
                let date = new Date()
                let day = date.getDate();
                let month = date.getMonth()+1;
                let year = date.getFullYear();
                let fullDate = year + "-" + month + "-" + day

                let reportFileExtension = '.pdf'
                let reportFileType = 'application/pdf'
                if(reportType == 'excel'){
                    reportFileExtension = '.xlsx'
                    reportFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
                if (this.exportData.length > 0) {
                    try {
                        this.$toast(this.$t('message.successes.exportedListReadyMoment'), {
                            classes: ['text--white'],
                            color: 'info',
                            timeout: 0,
                            icon: 'check',
                            iconColor: 'white',
                            x: 'center',
                            y: 'top'
                        })

                        let data = []
                        let apiEndpoint = '';
                        let reportFileName = ''

                        switch(this.exportSource){
                            case 'ipas':
                                apiEndpoint = '/ipas/export'
                                reportFileName = 'Import_Permit_Advices_Lists_' + fullDate
                                this.exportData.forEach(ed => {
                                    data.push({
                                        Ipa: ed.Ipa
                                    })
                                })
                                break;
                            case 'lcas':
                                apiEndpoint = '/lcas/export'
                                reportFileName = 'Letter_Credit_Advices_Lists_' + fullDate
                                this.exportData.forEach(ed => {
                                    data.push({
                                        Lca: ed.Lca
                                    })
                                })
                                break;
                            case 'packinglists':
                                apiEndpoint = '/packinglists/export'
                                reportFileName = 'Packing_Lists_' + fullDate
                                this.exportData.forEach(ed => {
                                    data.push({
                                        Pldo: ed.Pldo
                                    })
                                })
                                break;
                            case 'sdas':
                                apiEndpoint = '/sdas/export'
                                reportFileName = 'Shipping_Instructions_Lists_' + fullDate
                                this.exportData.forEach(ed => {
                                    data.push({
                                        Sda: ed.Sda
                                    })
                                })
                                break;
                        }

                        let exportedFile = await api.post(apiEndpoint, {
                            conditions: this.exportConditions,
                            data: data,
                            fields: this.exportFields,
                            type: reportType
                        }, {
                            responseType: 'blob'
                        })

                        const url = window.URL.createObjectURL(new Blob([exportedFile.data], {type: reportFileType}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.target = '_blank'
                        link.setAttribute('download', reportFileName + reportFileExtension);
                        link.click();
                        link.remove();
                        window.URL.revokeObjectURL(url);
                        //close notification
                        let cmp = this.$toast.getCmp()
                        cmp.close()
                    } catch (error) {
                        this.$toast.error(this.$t('message.errors.couldNotExportList') + ": " + error, {
                            timeout: 7000,
                            x: 'center',
                            y: 'top'
                        })
                    }
                } else {
                    alert(this.$t('message.noDataToExport'))
                }
            }
        }
    }
</script>

<style>
.v-snack__content {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}
</style>